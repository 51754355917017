<template>
  <v-dialog
      v-model="dialog"
      max-width="400"
  >
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>

      <v-card-text>
        {{ text }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="green darken-1"
            text
            @click="close"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'DefaultDialog',
  props: {
    title: {
      default: 'Titulo',
    },
    text: {
      default: 'Texto',
    },
    dialog: {
      default: false
    }
  },
  methods: {
    close(){
      this.$emit("update:closeDialog", false);
    }
  }
}
</script>

